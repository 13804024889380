<template>
  <v-app>
    <v-row justify="start" align="start" class="mt-4">
      <v-col cols="11" class="mx-auto">
        <p class="display-1 my-3">Add New Batch</p>
        <p>Add a new record to the Claims Processing Subsystem</p>
        <p>
          <strong>Please select a branch name to get started</strong> or create a new one
        </p>
        <!-- Beginning of Form -->
        <div class="my-5 py-5">
          <v-form ref="form" @submit.prevent="handleSubmit">
            <v-row>
              <v-col cols="4">
                <v-text-field
                  name="name"
                  label="Batch Name"
                  outlined
                  v-model="name"
                  :rules="nameRule"
                  id="name"
                  required
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  name="name"
                  label="Batch ID"
                  outlined
                  v-model="id"
                  :rules="idRule"
                  id="id"
                  required
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  name="name"
                  label="Created by"
                  placeholder="Full Name"
                  outlined
                  v-model="createdBy"
                  :rules="createdByRule"
                  id="created_by"
                  required
                  readonly
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="d-flex" v-if="!isLoading">
                <v-btn
                  type="submit"
                  color="green darken-4 white--text"
                  class="mr-5"
                  medium
                >
                  <span class="mx-8">Save</span>
                </v-btn>
              </v-col>
              <v-col cols="12" v-else class="text-center m-3">
                <!-- loading indicator -->
                <v-progress-circular
                  :width="4"
                  :size="40"
                  color="green darken-4"
                  indeterminate
                ></v-progress-circular>
              </v-col>
            </v-row>
          </v-form>
          <!-- End of the form -->
        </div>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "create-batch",
  data() {
    return {
      name: "",
      nameRule: [(v) => !!v || "Batch name is required"],
      id: "",
      idRule: [(v) => !!v || "Batch ID is required"],
      createdBy: "",
      createdByRule: [(v) => !!v || "Batch Author name is required"],
    };
  },
  methods: {
    async handleSubmit() {
      if (this.$refs.form.validate()) {
        try {
          const { name, createdBy: created_by, id: batch_id } = this;

          // returns created batch
          const response = await this.createBatch({
            data: { name, created_by, batch_id },
          });

          // created batch (new)
          const { data: batch } = response;

          // Redirect if batch was successfully created
          this.$router.push({
            name: "view-batch-claim-list",
            params: { batch_id: batch.id },
          });
        } catch (error) {
          this.$toast.error({
            message: error,
            type: "error",
          });
        }
      }
    },
    ...mapActions({ createBatch: "claimModule/createBatch" }),
  },
  computed: {
    ...mapGetters({ user: "user", isLoading: "claimModule/isLoading" }),
  },
  created() {
    this.createdBy = `${this.user.user_firstname} ${this.user.user_lastname}`;
  },
};
</script>

